<template>
  <div class="plans-wrapper">
    <div class="intro-wrapper m-auto text-center d-flex flex-column align-items-center">
      <div class="heading-text-wrapper">
        <h1 class="header font-outfit-bold text-center">
          {{ isAppSumoUser ? $t('pages.pricing.lifetimePlansSection.appSumoTitle') : $t('pages.pricing.lifetimePlansSection.title') }}
        </h1>

        <div class="subheader">
          <p class="font-outfit-regular m-0">
            {{ isAppSumoUser ? $t('pages.pricing.lifetimePlansSection.appSumoDescription') : $t('pages.pricing.lifetimePlansSection.description') }}
          </p>
        </div>
      </div>
      <div class="rating-section-wrapper">
        <rating-section />
      </div>
    </div>

    <div class="cards-wrapper text-center mt-lg-0">
      <el-row :gutter="14" class="cards-container d-flex flex-wrap m-auto text-center justify-content-center">
        <el-col
          v-for="plan in plans"
          :key="plan.title"
          :md="8"
          :class="{ 'mt-md-3 mt-lg-0': !plan.popular }"
          class="mb-lg-0"
        >
          <plan-card
            v-model="cardValues[period][plan.slug]"
            class="plan-card mb-md-0"
            dark
            :title="plan.title"
            :plan="getPlan(plan.slug)"
            :popular="plan.popular"
            :description="plan.description"
            :price="getPlan(plan.slug) ? getPlan(plan.slug).price : null"
            :currency-symbol="getPlan(plan.slug) ? getPlan(plan.slug).currency : null"
            :period="period"
            :options="plansOptions"
            :loading="loading"
            :button-loading="plan.slug === loadingPlanSlug"
            :disabled-features="plan.disabledFeatures"
            @choose-plan="value => onChoosePlan(plan.slug, value)"
            @contact="onContact"
          />
        </el-col>
      </el-row>
      <p class="prices-info">
        {{ $t('pages.pricing.pricesMayBeSubjectToAdditionalVatDependingOnYourCountry') }} 👆
      </p>
    </div>

    <!-- Dialogs -->
    <dialog-enterprise
      :enterprise-visible="dialogEnterpriseVisible"
      @close-enterprise="dialogEnterpriseVisible = false"
    />

    <dialog-confirm
      ref="dialogConfirm"
      :visible="dialogConfirm"
      :preview-data="previewData"
      :record="chosenData"
      @close-dialog="dialogConfirm = false"
      @confirm-dialog="confirmedUpdatePlan"
    />

    <dialog-thank-you
      :dialog-thank-you-visible="dialogThankYouVisible"
      :thank-you-data="chosenData"
      @close-dialog="closeDialog"
    />
    <!-- /Dialogs -->
  </div>
</template>

<script>
import DialogConfirm from '@/views/Payment/Dialogs/DialogConfirm'
import DialogEnterprise from '@/views/Payment/Dialogs/DialogEnterprise'
import DialogThankYou from '@/views/Payment/Dialogs/DialogThankYou'
import MixinPaddle from '@/mixins/paddle'
import PlanCard from '@/views/Payment/Components/Sections/Plans/PlanCard'
import RatingSection from '@/views/Payment/Components/Sections/Plans/RatingSection.vue'
import _find from 'lodash/find'
import _sortBy from 'lodash/sortBy'

import { formatPrice, getPriceInEuro } from '@/helpers/currencyHelper'
import { mapGetters } from 'vuex'
import { paymentQueryHelper } from '@/helpers/paymentQueryHelper'

export default {
  name: 'LifetimePlansSection',

  components: {
    RatingSection,
    DialogConfirm,
    DialogEnterprise,
    DialogThankYou,
    PlanCard
  },

  mixins: [
    MixinPaddle
  ],

  data () {
    return {
      loading: false,
      updatedPrices: false,
      loadingPlanSlug: null,
      dialogConfirm: false,
      dialogEnterpriseVisible: false,
      dialogThankYouVisible: false,
      previewData: {},
      chosenData: {},
      cardValues: {
        monthly: {
          Dreamer: 1,
          Founder: 1
        },
        annual: {
          Dreamer: 1,
          Founder: 1
        },
        lifetime: {
          Dreamer: 1,
          Founder: 1
        }
      },
      period: 'lifetime',
      allPlans: []
    }
  },

  computed: {
    ...mapGetters('user', [
      'isSubscribed',
      'isAppSumoUser'
    ]),

    usedPlans () {
      let plans = {
        3: this.isAppSumoUser ? 24 : 21,
        10: this.isAppSumoUser ? 33 : 31,
        30: this.isAppSumoUser ? 25 : 23
      }

      if (!this.isAppSumoUser) {
        plans = { ...plans, ...{ 1: 30 } }
      }

      return plans
    },

    plansOptions () {
      const options = [3, 10, 30]

      if (!this.isAppSumoUser) {
        options.push(1)
      }

      return _sortBy(options)
    },

    plans () {
      return [
        {
          title: 'Founder',
          slug: 'Founder',
          description: this.$t('pages.pricing.plansDescriptions.founder'),
          popular: false,
          price: 95,
          disabledFeatures: [12]
        }
      ]
    }
  },

  watch: {
    updatedPrices: {
      handler (newValue) {
        if (newValue) {
          const planInfo = paymentQueryHelper(false, this.$route.query)
          if (planInfo) {
            const plan = this.capitalizeFirstLetter(planInfo.plan)

            if (planInfo && planInfo.type) {
              this.cardValues[this.period][plan] = planInfo.noi
            }

            // Open dialog for checkout
            const foundPlan = this.findPlan(plan)
            this.choosePlan(foundPlan)
          }
        }
      },
      immediate: true
    }
  },

  created () {
    this.loading = true
    this.setupPaddle(this.getPlans)
  },

  methods: {
    getPlans () {
      this.$http.get('provider/plan').then(response => {
        this.allPlans = response.data.payload

        this.updatePlansPrices()
      })
    },
    onChoosePlan (slug, val) {
      const foundPlan = this.findPlan(slug)

      this.choosePlan(foundPlan)
    },
    updatePlansPrices () {
      const plansPrices = []

      this.allPlans.forEach(plan => {
        // eslint-disable-next-line no-undef
        Paddle.Product.Prices(plan.plan_id, (prices) => {
          plansPrices.push(prices)
          const { price, currency } = formatPrice(prices.price.net)

          plan.price = price
          plan.currency = currency

          if (plansPrices.length === this.allPlans.length) {
            this.loading = false
            this.updatedPrices = true
            this.cardValues.lifetime.Founder = this.isAppSumoUser ? 3 : 1
          }
        })
      })
    },
    onContact () {
      this.dialogEnterpriseVisible = true
    },
    choosePlan (plan) {
      this.loadingPlanSlug = plan.slug
      this.selectedPlan = plan
      if (this.isSubscribed &&
        this.$store.state.user.subscription.status !== 'deleted' &&
        !this.$store.state.user.freeTrial) {
        this.triggerPayment(plan)

        return
      }

      this.triggerPaddle(plan)
    },
    getPlan (planSlug) {
      const foundPlan = this.findPlan(planSlug)

      if (foundPlan) {
        return {
          currency: foundPlan.currency,
          price: Math.round(foundPlan.price),
          aiRequests: foundPlan.ai_requests
        }
      }

      return null
    },
    findPlan (planSlug) {
      const ideas = this.cardValues[this.period][planSlug]
      const planId = this.usedPlans[ideas]

      return _find(this.allPlans, ['id', planId])
    },
    triggerPayment (plan) {
      if (this.$store.state.user.subscription.id === plan.id) {
        this.$notify({ message: 'You already have this plan!', type: 'error' })
        this.loadingPlanSlug = null
      } else {
        this.$http.post(
          'provider/update-plan',
          { ...plan, confirmed: this.confirmed }
        ).then((response) => {
          if (response.status === 200) {
            this.previewData = response.data.payload
            this.dialogConfirm = true
            this.loadingPlanSlug = null
            this.chosenData = Object.assign({}, plan)

            if (this.confirmed) {
              this.$refs.dialogConfirm.loading = false
              this.$store.commit('user/setSubscription', response.data.payload)
              this.$intercom.trackEvent('Updated subscription')
              this.dialogThankYouVisible = true
              this.dialogConfirm = false
            }

            if (!this.previewData.success) {
              this.previewData = {}
              this.dialogConfirm = false
              this.triggerPaddle(plan)
            }
          }
        })
      }
    },
    triggerPaddle (plan) {
      if (this.$store.state.user.subscription.status !== 'deleted' && this.$store.state.user.subscription.id === plan.id) {
        this.$notify({ message: 'You already have this plan!', type: 'error' })
        this.loadingPlanSlug = null
      } else {
        const userPlanInfo = `${plan.slug}${plan.ideas}Lifetime`

        const startUserPlan = `${userPlanInfo}Start`
        this.$gtm.trackEvent({
          event: startUserPlan
        })

        const $this = this
        const referral = window.Rewardful && window.Rewardful.referral
        // eslint-disable-next-line no-undef
        Paddle.Checkout.open({
          product: plan.plan_id,
          email: this.$store.state.user.email,
          passthrough: JSON.stringify({ userId: this.$store.state.user.id, rewardful: { referral: referral } }),
          locale: localStorage.getItem('lang'),
          successCallback: (data) => {
            this.$http.post('provider/select-plan', plan).then((response) => {
              if (response.status === 200) {
                // Send GTM events
                const successCheckout = `checkout${userPlanInfo}Success`
                $this.$gtm.trackEvent({
                  event: successCheckout
                })
                // Send GTM Purchase event
                $this.$gtm.trackEvent({
                  event: 'Purchase (New)',
                  value: getPriceInEuro([plan.type, plan.slug, plan.ideas, plan.currency])
                })

                this.$intercom.update({
                  PlanType: `${plan.type.charAt(0).toUpperCase()}${plan.type.slice(1)}`,
                  PlanName: plan.slug,
                  PlanExpiry: response.data.payload.subscription.nextBillDate,
                  MaxIdeas: response.data.payload.subscription.ideas,
                  subscription_updated_at: this.$moment().unix()
                })
                this.$intercom.trackEvent('New subscription')

                this.$store.commit('user/setSubscription', response.data.payload)
                this.chosenData = Object.assign({}, plan)
                this.dialogThankYouVisible = true
              }
            })
          },
          loadCallback: () => {
            this.loadingPlanSlug = null
          },
          closeCallback: function (data) {
            this.loadingPlanSlug = null
            // Send GTM events
            const closeCheckout = `checkout${userPlanInfo}Close`
            $this.$gtm.trackEvent({
              event: closeCheckout
            })
          }
        })
      }
    },
    confirmedUpdatePlan () {
      this.$refs.dialogConfirm.loading = true
      this.confirmed = true
      this.triggerPayment(this.selectedPlan)
    },
    closeDialog () {
      this.dialogThankYouVisible = false
      this.$router.push({ name: 'home' })
    },
    capitalizeFirstLetter (string) {
      return string.charAt(0).toUpperCase() + string.slice(1)
    }
  }
}
</script>

<style scoped lang="scss">
.plans-wrapper {

  .intro-wrapper {

    .heading-text-wrapper {
      max-width: 700px;
      padding: 50px 20px 0;
    }

    .header {
      margin: 0 0 30px;
      font-size: 38px;
      font-weight: 700;

      @include media-breakpoint-up($md) {
        font-size: 52px;
      }
    }

    .subheader {
      max-width: 550px;
      margin: 0 auto 30px auto;

      p {
        font-size: 18px;

        @include media-breakpoint-up($md) {
          font-size: 24px;
        }
      }
    }

    .switch-wrapper {
      max-width: 334px;

      .arrow-text {
        position: absolute;
        right: 0;
        margin-top: 18px;

        @include media-breakpoint-up($md) {
          right: -45px;
          margin-top: 12px;
        }

        &-monthly {
          left: 20%;
          right: unset;
        }

        &-monthly,
        &-annual {
          img {
            transform: rotate(25deg);

            @include media-breakpoint-up($md) {
              transform: unset;
            }
          }
        }

        &-lifetime {
          right: 20%;
          flex-direction: row-reverse;

          img {
            transform: scaleX(-1) rotate(25deg);

            @include media-breakpoint-up($md) {
              transform: scaleX(-1);
            }
          }
        }

        p {
          color: #0E64E6;
          font-size: 20px;
          padding-top: 9px;

          @include media-breakpoint-up($md) {
            font-size: 24px;
            margin: 3px 11px 0;
          }
        }
      }

      ::v-deep {
        .form-switch-wrapper {
          background: $color-white;
          border: 1px solid #E9EDF6;
          border-radius: 48px;
          font-size: 12px;
          text-transform: uppercase;
        }

        .switch-input.active + .switch-label.form-switcher-blue {
          color: white;
        }

        .switch-label {
          font-family: $outfit-medium;
          color: #081E4A;
        }
      }
    }
  }

  .rating-section-wrapper {
    width: 100%;
    max-width: 900px;
    margin: 20px 0 30px;

    @include media-breakpoint-up($md) {
      margin: 30px 0 50px;
    }
    @include media-breakpoint-up($xl) {
      margin: 50px 0 70px;
    }
  }

  .cards-wrapper {
    width: 100%;
    margin-top: 70px;

    @include media-breakpoint-up($md) {
      margin-top: 20px;
      background-image: url('~@/assets/img/pricing/lifetime-deal/backgroundLifetime.svg');
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center;
    }

    .prices-info {
      margin-top: 10px;

      @include media-breakpoint-up($lg) {
        margin-top: 50px
      }
    }

    .cards-container {
      max-width: 900px;
      padding: 0 13px;

      .el-col {
        margin-bottom: 20px;
      }

      ::v-deep {
        @include media-breakpoint-between($md, $lg) {
          .card-wrapper.popular {
            .plan-title {
              margin-top: 16px;
            }
          }
        }
      }
    }
  }
}
</style>
