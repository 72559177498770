<template>
  <div class="rating-wrapper">
    <a href="https://www.capterra.com/p/204342/IdeaBuddy/reviews/" target="_blank" rel="noreferrer">
      <img src="@/assets/img/pricing/lifetime-deal/rating-capterra.webp" alt="rating-capterra">
    </a>
    <a
      href="https://www.forbes.com/advisor/business/software/best-business-plan-software/"
      target="_blank"
      rel="noreferrer"
    >
      <img src="@/assets/img/pricing/lifetime-deal/rating-forbes.webp" alt="rating-forbes">
    </a>
    <a href="https://www.g2.com/products/ideabuddy/reviews" target="_blank" rel="noreferrer">
      <img src="@/assets/img/pricing/lifetime-deal/rating-g2.webp" alt="rating-g2">
    </a>
  </div>
</template>

<script>
export default {
  name: 'RatingSection'
}
</script>

<style scoped lang="scss">
.rating-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  max-width: 900px;
  row-gap: 30px;
  column-gap: 30px;
  margin: 0 auto;
  padding-bottom: 15px;
  //background: red;

  @include media-breakpoint-up($sm) {
    align-items: center;
  }

  @include media-breakpoint-up($md) {
    flex-direction: row;
    justify-content: space-around;
  }

  a {
    img {
      width: 200px;
      //@include media-breakpoint-up($md) {
      //  width: 220px;
      //}
    }
  }
}

</style>
